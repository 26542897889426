export const getPictureSources = (image?: any) => {
  const sources = [];
  if (image?.mobile) {
    sources.push({
      srcSet: image.mobile,
      type: 'image/webp',
      media: '(max-width: 767px)',
    });
  }
  if (image?.desktop) {
    sources.push({
      srcSet: image.desktop,
      type: 'image/webp',
      media: '(min-width: 768px)',
    });
  }
  if (image?.mobileFallback) {
    sources.push({
      srcSet: image.mobileFallback,
      media: '(max-width: 767px)',
    });
  }
  if (image?.desktopFallback) {
    sources.push({
      srcSet: image.desktopFallback,
      media: '(min-width: 768px)',
    });
  }
  return sources;
};
