import tw from 'tailwind-styled-components';
import styled from 'styled-components';

export const Container = styled.section`
  @media screen and (min-width: 2000px) {
    overflow: initial;
  }
`;
export const ContainerStyle = `relative md:my-20 sm:my-0 py-16 md:py-0 min-w-full overflow-hidden`;
export const TopRight = tw.div`
  absolute
  top-2
  md:-right-32
  sm:-right-10
  md:w-metaBall
  md:h-metaBall
  sm:w-metaBallMobile
  sm:h-metaBallMobile
`;

export const TopLeft = tw.div`
  absolute
  top-5
  md:-left-32
  sm:-top-10
  sm:-left-10
  md:w-metaBall
  md:h-metaBall
  sm:w-metaBallMobile
  sm:h-metaBallMobile
`;

export const BottomRight = tw.div`
  absolute
  bottom-0
  md:bottom-10
  md:-right-32
  sm:-right-10
  md:w-metaBall
  md:h-metaBall
  sm:w-metaBallMobile
  sm:h-metaBallMobile
`;

export const BottomLeft = tw.div`
  absolute
  bottom-10
  md:bottom-0
  md:-left-32
  sm:-left-10
  md:h-metaBall
  md:w-metaBall
  sm:w-metaBallMobile
  sm:h-metaBallMobile
`;

export const MetaBallWrapper = tw.div`
  relative
  md:h-metaBall
  md:w-metaBall
  sm:w-metaBallMobile
  sm:h-metaBallMobile
`;
