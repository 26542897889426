import React from 'react';
import { Container, TopRight, TopLeft, BottomRight, BottomLeft, ContainerStyle } from './styles';
import { IMetaBallContainerProps } from './types';
import {
  BlobUpperCornerMobile,
  BlobLowerCornerMobile,
  BlobUpperCorner,
  BlobLowerCorner,
} from '../Icons/icons';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export const MetaBallContainer = ({
  children,
  topRightImage,
  bottomRightImage,
  bottomLeftImage,
  topLeftImage,
}: IMetaBallContainerProps) => (
  <Container
    data-testid="metaBallContainer"
    className={ContainerStyle}
    data-print="hide"
    id="metaball-container"
  >
    {topRightImage && (
      <TopRight>
        <div className="relative">
          <div
            className="z-10 absolute md:h-metaBall md:w-metaBall sm:w-metaBallMobile sm:h-metaBallMobile"
            id="higher-blob"
          >
            <div className="hidden md:flex">
              <BlobUpperCorner />
            </div>
            <div className="flex md:hidden">
              <BlobUpperCornerMobile />
            </div>
          </div>
          <div className="z-20 absolute">
            <img
              data-src={topRightImage}
              alt=""
              className="z-20 p-5 relative lazyload"
              width="260"
              height="260"
            />
          </div>
        </div>
      </TopRight>
    )}
    {bottomRightImage && (
      <BottomRight>
        <div className="relative">
          <div className="z-10 absolute md:h-metaBall md:w-metaBall sm:w-metaBallMobile sm:h-metaBallMobile">
            <div className="hidden md:flex">
              <BlobLowerCorner />
            </div>
            <div className="flex md:hidden">
              <BlobLowerCornerMobile />
            </div>
          </div>
          <div className="z-20 absolute">
            <img
              data-src={bottomRightImage}
              alt=""
              className="z-20 relative lazyload"
              width="260"
              height="260"
            />
          </div>
        </div>
      </BottomRight>
    )}
    {bottomLeftImage && (
      <BottomLeft>
        <div className="relative bottomLeftMetaBall">
          <div
            className="z-10 absolute md:h-metaBall md:w-metaBall sm:w-metaBallMobile sm:h-metaBallMobile left-10 bottomLeftMetaBall"
            id="lower-blob"
          >
            <div className="hidden md:flex">
              <BlobLowerCorner />
            </div>
            <div className="flex md:hidden">
              <BlobLowerCornerMobile />
            </div>
          </div>
          <div className="z-20 absolute">
            <img
              data-src={bottomLeftImage}
              alt=""
              className="z-20 relative left-10 lazyload"
              width="260"
              height="260"
            />
          </div>
        </div>
      </BottomLeft>
    )}
    {topLeftImage && (
      <TopLeft>
        <div className="relative">
          <div className="z-10 absolute md:h-metaBall md:w-metaBall sm:w-metaBallMobile sm:h-metaBallMobile">
            <div className="hidden md:flex">
              <BlobUpperCorner />
            </div>
            <div className="flex md:hidden">
              <BlobUpperCornerMobile />
            </div>
          </div>
          <div className="z-20 absolute">
            <img
              data-src={topLeftImage}
              alt=""
              className="z-20 relative lazyload"
              width="260"
              height="260"
            />
          </div>
        </div>
      </TopLeft>
    )}
    {children}
  </Container>
);
